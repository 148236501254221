import React from 'react'
import './magazin-featured-section.less'
import LatestNewsItem from 'components/LatestNewsItem/LatestNewsItem'
import magazinLogo from '../../images/magazin_logo.png'
import Button from 'components/button/Button'
import { Link } from 'gatsby'
import fallbackImage from '../../images/fallback.png'

type MagazinFeaturedData = {
  date: string
  description: string
  heading: string
  imageSrc: string
  link: string
}

// Some of the articles in MagasinK doesnt have an image so we need to use a fallback image
export function mapMagasinKAPIResponse(data: any) {
  return data.edges.map((edge: any) => {
    return {
      date: edge.node.date_gmt,
      description: edge.node.excerpt.rendered,
      heading: edge.node.title.rendered,
      imageSrc: edge.node._embedded?.wp_featuredmedia
        ? edge.node._embedded.wp_featuredmedia[0]?.source_url ?? fallbackImage
        : fallbackImage,
      link: edge.node.link,
    }
  })
}

interface MagazinFeaturedSectionProps {
  heading: string
  description: string
  latestArticles: MagazinFeaturedData[]
  buttonLabel: string
  ctaHref: string
}

export const MagazinFeaturedSection = ({
  heading,
  description,
  latestArticles,
  buttonLabel,
  ctaHref,
}: MagazinFeaturedSectionProps) => {
  return (
    <div className="magazin-featured">
      <div className="Page-container">
        <div className="magazin-featured__header">
          <h4 className="magazin-featured__heading">{heading}</h4>
          <Link target="_blank" to="https://magasink.se/">
            <img className="magazin-feature__magazin-logo" src={magazinLogo} alt="" />
          </Link>
        </div>
        <div className="magazin-featured__content">
          <p className="magazin-featured__content-text">{description}</p>
        </div>
        <div className="magazin-featured__latest-articles">
          <div className="magazin-featured__latest-articles--featured">
            <div className="magazin-featured__latest-articles--featured__item">
              <LatestNewsItem
                key={0}
                date={latestArticles[0].date}
                description={latestArticles[0].description}
                heading={latestArticles[0].heading}
                imageSrc={latestArticles[0].imageSrc}
                link={latestArticles[0].link}
              />
            </div>
          </div>
          <div className="magazin-featured__latest-articles--other">
            {latestArticles.slice(1).map((data, index) => (
              <LatestNewsItem
                squareImage
                smallHeading
                key={index + 1}
                date={data.date}
                description={data.description}
                heading={data.heading}
                imageSrc={data.imageSrc}
                link={data.link}
              />
            ))}
          </div>
        </div>
        <div className="magazin-featured__cta">
          <Button
            target="_blank"
            href={ctaHref}
            className="button button--outline button--rounded-new blue events-and-webinars-section__cta-mobile"
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  )
}
